import moment from "moment";
import PropTypes from "prop-types";
import { useState } from "react";
import Text from "./Text";

const InputField = ({
  restrictYear,
  notBackdate,
  disabled,
  error,
  register = () => {},
  id,
  name,
  type,
  maxLength = 256,
  placeholder,
  variant = "outlineBottom",
  bg = "bg-lightGrey",
  fontSize = "text-base",
  borderRadius = "rounded",
  className = "",
  defaultValue,
  onChangeValue = () => {},
  change,
  inputLength,
  getValues,
  bottomMargin = "mb-6",
  onValueChange = () => {},
  value=undefined,
}) => {
  const inputStyleType = {
    normal: "normal",
    outline: "outline",
    outlineBottom: "outlineBottom",
    error: "error",
  };

  const inputStyle = {
    normal: "border-0",
    outline: "border-gray-200",
    outlineBottom: "border-b-gray-400",
    error: "border-error border-b-error",
  };

  const inputField = register(id);
  const minValue =
    type === "datetime-local"
      ? moment().format("YYYY-MM-DDThh:mm")
      : moment().format("YYYY-MM-DD");
  const fieldValue = getValues ? (getValues(id) ? getValues(id) : "") : "";
  const [inputValue, setInputValue] = useState(fieldValue);
  const today = new Date();
  const oneYearFromToday = new Date();
  const afterOneYear = oneYearFromToday.setFullYear(today.getFullYear() + 1);
 
 
  return (
    <div className={`w-full ${bottomMargin}`}>
      <input
        id={id}
        type={type}
        name={name}
        placeholder={placeholder}
        variant={inputStyleType[variant]}
        className={`${
          inputStyle[variant]
        } w-full border text-gray-900 h-12 px-4 outline-none placeholder:text-grey-400 ${bg} ${borderRadius} ${fontSize} ${className} ${
          disabled && "opacity-60 pointer-events-none"
        } ${error ? " border border-error border-b-error" : ""}`}
        disabled={disabled !== undefined ? disabled : false}
        maxLength={maxLength}
        onWheel={(e) => e.target.blur()}
        {...inputField}

          onChange={(e) => {
            onValueChange(e);
          }}
 
        onBlur={(e) => {
          if (type === 'date' && notBackdate) {
            const enteredDate = new Date(e.target.value);
            const currentDate = new Date();
            if (enteredDate < currentDate) {  
              alert('Enter a valid date (not backdated value)');
              e.target.value = '';
              return;
            }
            if ((enteredDate > afterOneYear)&& restrictYear) {
              alert(`The date entered ${e.target.value} is not valid. Please enter a valid year`);
              e.target.value = '';
              return;
            }
          }  
          inputField?.onChange(e);
          onChangeValue(e);
          setInputValue(e.target.value);
        }}
        {...(change && { onChange: change })}
        {...(value !== undefined && { value: value })}
        defaultValue={defaultValue}
        min={notBackdate ? minValue : undefined}
        max={restrictYear ? null: '9999-12-31'}
      />
      {error && (
        <div className="text-sm text-error pt-1">
          {error.length != 0 && error.message}
        </div>
      )}
      {inputLength && (
        <Text className="text-xs text-gray-400 absolute bottom-2 right-2">
          {inputValue.length}/{inputLength}
        </Text>
      )}
    </div>
  );
};
 
export default InputField;
 
InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  register: PropTypes.func,
  maxLength: PropTypes.number,
  bg: PropTypes.string,
  fontSize: PropTypes.string,
  borderRadius: PropTypes.string,
};
 