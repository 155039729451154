import React, { useState } from "react";
import LoginContent from "organisms/loginContent";
import LoginForm from "organisms/loginForm";
import OtpScreen from "organisms/otpScreen";
import MultiCompanySelect from "organisms/multiCompanySelect";
import LoginOption from "organisms/LoginOption";
import CandidateLoginForm from "organisms/CandidateLoginForm";
import CandidateLoginContent from "organisms/CandidateLoginContent";
import Footer from "organisms/Footer";
const LoginTemplate = ({
  loginDetails,
  apiError,
  handleGetOTP,
  handleLogin,
  resendOtp,
  editMobile,
  handleSubmit,
  control,
  register,
  country,
  handleCountryChange,
  countryOptionsList,
  onCompanySelect,
  companyList,
  setError,
  handleLoginOptionSelect,
  setValue,
  onClickBackArrow,
  isAdmin,
  getValues,
  companyDetails,
  errors,
}) => {
  const renderSwitch = () => {
    switch (loginDetails.step) {
      case 0:
        return (
          <LoginOption handleLoginOptionSelect={handleLoginOptionSelect} />
        );
      case 1:
        return (
          <>
            {!isAdmin ? (
              <CandidateLoginForm
                handleSubmit={handleSubmit}
                control={control}
                register={register}
                handleCountryChange={handleCountryChange}
                countryOptionsList={countryOptionsList}
                country={country}
                onSubmit={handleGetOTP}
                loginDetails={loginDetails}
                error={apiError}
                setError={setError}
                defaultValues={{ mobileNumber: loginDetails.mobileNumber }}
                onClickBackArrow={onClickBackArrow}
                getValues={getValues}
                setValue={setValue}
                companyDetails={companyDetails}
                errors={errors}
              />
            ) : (
              <LoginForm
                handleSubmit={handleSubmit}
                control={control}
                register={register}
                handleCountryChange={handleCountryChange}
                countryOptionsList={countryOptionsList}
                country={country}
                onSubmit={handleGetOTP}
                loginDetails={loginDetails}
                error={apiError}
                setError={setError}
                defaultValues={{ mobileNumber: loginDetails.mobileNumber }}
                onClickBackArrow={onClickBackArrow}
                getValues={getValues}
                setValue={setValue}
              />
            )}
          </>
        );

      case 2:
        return (
          <OtpScreen
            control={control}
            register={register}
            setValue={setValue}
            handleLogin={handleLogin}
            loginDetails={loginDetails}
            editMobile={editMobile}
            resendOtp={resendOtp}
            error={apiError}
            isAdmin={isAdmin}
            companyDetails={companyDetails}
          />
        );
      case 3:
        return (
          <MultiCompanySelect
            companyList={companyList}
            onClick={onCompanySelect}
          />
        );
    }
  };
  return (
    <div className="grid md:grid-cols-2 overflow-hidden bg-Mist">
      <div className="h-[50vh] small-screen:h-[60vh] md:h-full">
        {isAdmin ? (
          <LoginContent />
        ) : (
          <CandidateLoginContent companyDetails={companyDetails} />
        )}
      </div>
        <div className="">{renderSwitch()}</div>
    </div>
  );
};
export default LoginTemplate;
