import Select from "react-select";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

const Dropdown = ({
  options,
  name,
  isDisabled,
  isLoading,
  isClearable,
  isSearchable,
  isMulti,
  onChange,
  defaultValue,
  className,
  value,
  error,
  control,
  customComponents = {},
  mainClass = "mb-6",
  menuMaxHeight = 250,
  ...properties
}) => {
  return (
    <div className={mainClass}>
      <Controller
        name={name}
        control={control}
        onChange={onChange}
        render={({ field }) => (
          <Select
            isMulti={isMulti}
            classNamePrefix="select"
            placeholder={`${
              properties.placeholder ? properties.placeholder : "Placeholder"
            }`}
            value={value}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            name={name}
            closeMenuOnSelect={!isMulti}
            options={options}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#F3F3F6",
                primary: "#F3F3F6",
              },
            })}
            styles={{
              container: (base) => ({
                ...base,
                zIndex: 1,
              }),
              menuPortal: (base) => ({ ...base, zIndex: 10000 }),
              dropdownIndicator: (provided, state) => ({
                ...provided,
                transition: "all .2s ease",
                transform: state.selectProps.menuIsOpen && "rotate(180deg)",
              }),
              menu: (provided) => ({
                ...provided,
                maxHeight: menuMaxHeight,
                overflowY: "auto",
                zIndex: 10000,
                position: "relative",
              }),
              menuList: (provided) => ({
                ...provided,
                maxHeight: menuMaxHeight,
                overflowY: "auto",
                zIndex: 10000,
              }),
              option: (provided, state) => ({
                ...provided,
                color: state.isSelected ? "#000000" : provided.color,
              }),
            }}
            menuPortalTarget={typeof window !== 'undefined' ? document.body : null}
            onChange={(val) => {
              if (isMulti) {
                let values = val.map((v) => v.value);
                field.onChange(values);
              } else {
                field.onChange(val.value);
              }
              onChange && onChange(val);
            }}
            className={`${className} ${
              error ? " border border-error border-b-error" : ""
            }`}
            instanceId="long-value-select"
            components={customComponents}
          />
        )}
      />
      {error && (
        <div className="text-sm text-error pt-1">{error && error.message}</div>
      )}
    </div>
  );
};

export default Dropdown;

Dropdown.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  menuMaxHeight: PropTypes.number,
};

Dropdown.defaultProps = {
  isDisabled: false,
  isLoading: false,
  isClearable: false,
  isSearchable: false,
  isMulti: false,
};
